import { ReactElement, useEffect } from "react";

import { useRouter } from "next/router";

import { AuthenticatedLayout } from "@/components/layouts";

/**
 * This `/` page simply redirects to `/dashboard` when the user is logged in.
 */
const NextIndexPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace(`/dashboard`);
  }, []);

  return null;
};

NextIndexPage.getLayout = (page: ReactElement) => (
  <AuthenticatedLayout title="Dashboard">{page}</AuthenticatedLayout>
);

export default NextIndexPage;
